<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :propsParam="{ any: 'false' }">
      <div slot="content-buttons">
        <Button _key="btnCustomerCreate" title="Cliente" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="customerCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CustomerListView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "CRM",
        title: "Cliente",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/crm/customer/get-all",
        urlDeleteAllApi: "/api/v1/crm/customer/delete",
        showChecks: true,
        headerTable: [
          {
            field: "companyName",
            title: "Razão Social/Nome",
            type: "link",
            routeName: "customerUpdate",
            iconSearch: true,
          },
          {
            field: "tradeName",
            title: "Fantasia/Apelido",
            type: "text",
            iconSearch: true,
          },
          {
            field: "document",
            title: "CNPJ/CPF",
            type: "text",
            iconSearch: true,
          },
          {
            field: "categoryCustomerName",
            title: "Categoria",
            type: "text",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            iconSearch: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "customerCreate",
      });
      this.removeLoading(["btnCustomerCreate"]);
    },
  },
};
</script>
